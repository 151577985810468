<template>
  <div class="common_coupon_card_container">
    <img
      :src="info.couponCover?info.couponCover:require('../assets/img/coupon_default.png')"
      alt=""
      class="coupon_cover"/>
    <p class="title">{{info.couponName}}</p>
    <p class="time">有效期：{{wrapDate()}}</p>
    <p class="btn_box">
      <span class="desc">{{info.applyItem?'指定商品可用':"所有商品可用"}}</span>
      <span class="btn"
            :class="type!==0?'disable':null"
            @click="handleClick">{{wrapStatus()}}</span>
    </p>
  </div>
</template>
<script>

import moment from "moment-timezone";

export default {
  name: 'couponCard',
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    wrapDate() {
      const start = moment(this.info.effectiveDate).format('YYYY-MM-DD HH:mm:ss');
      const end = moment(this.info.invalidDate).format('YYYY-MM-DD HH:mm:ss');
      return `${start}~${end}`;
    },
    wrapStatus() {
      // status:使用状态 0:未使用,1:已使用,2:已过期
      const obj = { 0: '立即使用', 1: '已使用', 2: '已过期' };
      return obj[this.type];
    },
    handleClick() {
      if (this.type === 0) {
        this.getData();
      }
    },
    getData() {
      this.$store.dispatch('coupon/getCouponGoods', {
        couponId: this.info.couponId,
        userCouponId: this.info.userCouponId,
      });
    },
  },

};
</script>
<style lang="scss" scoped>
  .common_coupon_card_container{
    width: 222px;
    height: 280px;
    background: #FFFFFF;
    box-shadow: 0px 2px 16px 0px rgba(202,205,214,0.47);
    border-radius: 6px;
    overflow: hidden;
    margin-right:10px;
    margin-bottom: 10px;
    &:nth-child(4n){
      margin-right:0;
    }
    .coupon_cover{
      width:100%;
      height: 132px;
      max-width: 100%;
      margin-bottom:10px;
    }
    .title{
      height: 28px;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding:0 10px;
    }
    .time{
      height: 60px;
      font-size: 14px;
      font-weight: 400;
      color: #A0A2AB;
      line-height: 20px;
      padding:0 10px;
      margin-bottom:4px;
    }
    .btn_box{
      padding:0 10px;
      .desc{
        display:inline-block;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #A0A2AB;
        line-height: 20px;
      }
      .btn{
        display:inline-block;
        width:79px;
        height:26px;
        background: linear-gradient(52deg, #3377FF 0%, #A0BFFF 100%);
        font-size: 12px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 26px;
        text-align: center;
        border-radius: 20px;
        margin-left:33px;
        cursor: pointer;

      }
      .disable{
        background:#CCC;
      }
    }

  }

</style>
