<template>
  <div class="user_coupons_list_container">
    <my-tabs :tabs="tabs" v-model:activeTab="activeTab" class="tabs"></my-tabs>
    <div class="line"></div>
    <div class="list_container">
      <coupon-card
        v-for="item in couponList"
        :key="item.couponId"
        :info="item"
        :type="activeTab"
      ></coupon-card>
      <p v-if="!couponList.length" class="empty_tip">暂无优惠券</p>
    </div>
  </div>
</template>
<script>
import MyTabs from '@/components/MyTabs.vue';
import CouponCard from '@/components/CouponCard.vue';
import { connectState } from '../../utils/wyUtil';

export default {
  components: { CouponCard, MyTabs },
  data() {
    return {
      tabs: [// status:使用状态 0:未使用,1:已使用,2:已过期
        { label: '未使用', value: 0, status: 0 },
        { label: '已使用', value: 1, status: 1 },
        { label: '已过期', value: 2, status: 2 },
      ],
      activeTab: 0,
    };
  },
  watch: {
    activeTab() {
      this.getUserCoupons();
    },
  },
  computed: {
    ...connectState('coupon', ['couponList']),
  },
  created() {
    this.getUserCoupons();
  },
  methods: {
    getUserCoupons() {
      this.$store.dispatch('coupon/getCouponList', {
        status: this.tabs[this.activeTab].status,
      });
    },
  },
};

</script>
<style lang="scss" scoped>
  .user_coupons_list_container{
    background:#FFF;
    padding:15px 0;
    border-radius: 6px;
    .line{
      width: 100%;
      height: 2px;
      background: #F8F8F8;
      margin-top:15px;
      margin-bottom:15px;
    }
    .tabs{
      padding:0 15px;
    }
    .list_container{
      padding:0 15px;
      display:flex;
      flex-flow:row wrap;
      align-items: flex-start;
      .empty_tip{
        width:100%;
        text-align: center;
        line-height: 50px;
        color:#bbb;
        margin-top:50px;
      }
    }
  }

</style>
